import Hotjar from '@hotjar/browser';

// i18n
import './locales/i18n';

// highlight
import './utils/highlight';

// scroll bar
import 'simplebar/src/simplebar.css';

// lightbox
import 'react-image-lightbox/style.css';

// map
import 'mapbox-gl/dist/mapbox-gl.css';

// editor
import 'react-quill/dist/quill.snow.css';

// slick-carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';

import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { useEffect } from "react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";


import * as Sentry from '@sentry/react';
import { BrowserTracing } from "@sentry/tracing";  // For tracing
// import { ProfilingIntegration } from "@sentry/profiling"; // For profiling
import { Replay } from "@sentry/replay"; // For session replay

// @mui
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
// redux
import { store, persistor } from './redux/store';
// contexts
import { SettingsProvider } from './contexts/SettingsContext';
import { CollapseDrawerProvider } from './contexts/CollapseDrawerContext';

// Check our docs
// https://docs-minimals.vercel.app/authentication/ts-version

import { AuthProvider } from './contexts/JWTContext';

import App from './App';
import registerServiceWorker from './serviceWorkerRegistration';
// import reportWebVitals from './reportWebVitals';

const siteId = process.env.REACT_APP_HOTJAR_KEY_PROD;
const hotjarVersion = 6;
Hotjar.init(siteId, hotjarVersion);

// Initialize Sentry only in production environment
if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: "https://a4dfc5311f4eb4961f62f4ee828f822a@o4507940106141696.ingest.de.sentry.io/4507940394106960",
    integrations: [
      // Integrate with React Router for tracing
      new BrowserTracing({
        // Use the `react-router` integration for routing-based tracing
        tracingOrigins: [process.env.REACT_APP_HOST_API, /^\//],
        routingInstrumentation: Sentry.withSentryReactRouterV6Routing({
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        }),
      }),
      // Integrate with Replay
      new Replay(),
    ],
    // Capture 10% of transactions, adjust this to balance cost and data collection
    tracesSampleRate: 0.1, // Reduced to 10% to lower costs
    // Capture 10% of session replays
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%
    // Capture all session replays when there is an error
    replaysOnErrorSampleRate: 1.0,
  });
}

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <AuthProvider>
    <HelmetProvider>
      <ReduxProvider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <SettingsProvider>
              <CollapseDrawerProvider>
                <BrowserRouter>
                  <App />
                </BrowserRouter>
              </CollapseDrawerProvider>
            </SettingsProvider>
          </LocalizationProvider>
        </PersistGate>
      </ReduxProvider>
    </HelmetProvider>
  </AuthProvider>
);

registerServiceWorker();
// reportWebVitals();
